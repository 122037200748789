@font-face {
  font-family: WorkSans;
  font-display: auto;
  src: url(./WorkSans/WorkSans-Regular.ttf);
  src: local(WorkSans-Regular), url(./WorkSans/WorkSans-Regular.ttf);
  font-style: normal;
}

@font-face {
  font-family: WorkSans;
  font-display: auto;
  src: url(./WorkSans/WorkSans-SemiBold.ttf);
  src: local(WorkSans-SemiBold), url(./WorkSans/WorkSans-SemiBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: WorkSans;
  font-display: auto;
  src: url(./WorkSans/WorkSans-Bold.ttf);
  src: local(WorkSans-Bold), url(./WorkSans/WorkSans-Bold.ttf);
  font-style: bold;
}

@font-face {
  font-family: WorkSans;
  font-display: auto;
  src: url(./WorkSans/WorkSans-Light.ttf);
  src: local(WorkSans-Light), url(./WorkSans/WorkSans-Light.ttf);
  font-style: 100 300;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  user-select: none;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  font-size: responsive 14px 24px;
  /* min-size, max-size */
  font-range: 240px 2600px;
  /* viewport widths between which font-size is fluid */

  line-height: responsive 20px 30px;
  line-height-range: 240px 2600px;

  letter-spacing: responsive 0px 1px;
  letter-spacing-range: 240px 2600px;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;

  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(128, 128, 128, 0)); */

  font-family: WorkSans;
  /* -webkit-overflow-scrolling: auto; */
}

:global(#root) {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

:global(a) {
  color: inherit;
  text-decoration: inherit;
}
